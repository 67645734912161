import { useEffect, useState, React } from "react";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import {
  Box,
  MenuItem,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useQueryClient, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #eee",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const TypographyLabel = styled.h2`
  font-weight: bold;
  color: #31d703;
`;

const BoxModalStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const BoxModal = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
`;
const BoxModalBtn = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
`;
const BoxModalFlex = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
`;
const BoxModalFlexN = styled.div`
  display: flex;
  flex-direction: column;
  margin: -12px;
`;

const ErrorText = styled.p`
  color: red;
`;
const ButtonStyle = styled.button`
  width: 150px;
  height: 50px;
  background-color: #31d703;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border-color: #31d703;
  border-radius: 5px;
`;
export default function AddPaymentModal({ openAdd, setOpenAdd, currentRow }) {
  const accessToken = localStorage.getItem("userStorage")
    ? JSON.parse(localStorage.getItem("userStorage")).AccessToken
    : null;
  const navigate = useNavigate();
  const schema = yup.object().shape({
    PaymentID: yup
      .string("validation.invalid_PaymentID")
      .required("PaymentID is required"),
    ShipmentID: yup
      .string("validation.invalid_ShipmentID")
      .required("ShipmentID is required"),
    PaymentMethodID: yup
      .string("validation.invalid_PaymentMethodID")
      .required("PaymentMethodID is required"),
    Value: yup.string("validation.invalid_Value").required("Value is required"),
    Notes: yup.string("validation.invalid_Notes").required("Notes is required"),
  });
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
  });
  const setValues = (obj) => {
    for (let i = 0; i < Object.keys(obj).length; i++) {
      setValue(Object.keys(obj)[i], obj[Object.keys(obj)[i]]);
    }
  };
  const handleCloseSnack = () => {
    setSnack(false);
  };
  const [snack, setSnack] = useState(false);

  const queryClient = useQueryClient();
  const { data: PaymentData } = useQuery(
    "payment_list",
    async () => {
      const PaymentData = await axios.get(
        "https://trackshipments.message-express.com/APIs/api/PaymentMethods/V1/GetAll",
        {
          headers: {
            CompanyID: "211801",
            AccessToken: `${accessToken}`,
          },
        }
      );
      return PaymentData.data.Data;
    },
    {
      onError: (error) => {
        if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized response
          localStorage.clear();
          navigate("/");
        }
      },
    }
  );
  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  async function regeisterHandler(data) {
    setDisable(true);
    setIsLoading(true);
    const userSchema = {
      PaymentID: 0,
      ShipmentID: 0,
      PaymentMethodID: 0,
      Value: 0,
      Notes: "",
    };
    try {
      const response = await axios.post(
        "https://trackshipments.message-express.com/APIs/api/Payments/V1/Insert",
        { ...userSchema, ...data },
        {
          headers: {
            CompanyID: "211801",
            AccessToken: `${accessToken}`,
          },
        }
      );
      queryClient.invalidateQueries("DataGridSignUp");
      setIsLoading(false);
      setDisable(false);
      setOpenAdd(false);
      reset();
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status === 401) {
        // Handle 401 Unauthorized response
        localStorage.clear();
        navigate("/");
      } else {
        setIsLoading(false);
        setDisable(false);
      }
    }
  }
  useEffect(() => {
    setValues(currentRow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRow]);
  return (
    <div>
      <BoxModal>
        <Modal
          open={openAdd}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form onSubmit={handleSubmit(regeisterHandler)}>
            <Box sx={style}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <TypographyLabel>Add Payment</TypographyLabel>
              </Box>
              <BoxModalStyle>
                <BoxModalFlex>
                  <BoxModalFlexN>
                    <TextField
                      id="outlined-basic"
                      label="PaymentID"
                      defaultValue={0}
                      variant="outlined"
                      sx={{ width: "222px", display: "none" }}
                      {...register("PaymentID", {
                        required: "* PaymentID Is Required",
                      })}
                    />
                    <ErrorText>{errors.PaymentID?.message}</ErrorText>
                  </BoxModalFlexN>
                  <BoxModalFlexN>
                    <TextField
                      id="outlined-basic"
                      label="ShipmentID"
                      style={{ display: "none" }}
                      defaultValue={currentRow.ShipmentID}
                      variant="outlined"
                      {...register("ShipmentID", {
                        required: "* ShipmentID Is Required",
                      })}
                    />
                    <ErrorText>{errors.ShipmentID?.message}</ErrorText>
                  </BoxModalFlexN>
                </BoxModalFlex>
                <BoxModalFlex>
                  <BoxModalFlexN>
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Payment Method"
                      sx={{ width: "220px" }}
                      {...register("PaymentMethodID", {
                        required: "* PaymentMethod Is Required",
                      })}
                    >
                      {PaymentData?.map((item) => (
                        <MenuItem
                          key={item.PaymentMethodID}
                          value={item.PaymentMethodID || ""}
                        >
                          {item.EnglishName}
                        </MenuItem>
                      ))}
                    </TextField>
                    <ErrorText>{errors.PaymentMethodID?.message}</ErrorText>
                  </BoxModalFlexN>
                  <BoxModalFlexN>
                    <TextField
                      id="outlined-basic"
                      label="Value"
                      type="number"
                      variant="outlined"
                      {...register("Value", {
                        required: "* Value Is Required",
                      })}
                    />
                    <ErrorText>{errors.Value?.message}</ErrorText>
                  </BoxModalFlexN>
                </BoxModalFlex>
                <BoxModalFlex>
                  <BoxModalFlexN>
                    <TextField
                      id="outlined-basic"
                      label="Notes"
                      variant="outlined"
                      {...register("Notes", {
                        required: "* Notes Is Required",
                      })}
                    />
                    <ErrorText>{errors.Notes?.message}</ErrorText>
                  </BoxModalFlexN>
                </BoxModalFlex>
                <BoxModalBtn>
                  <ButtonStyle type="submit">
                    {isLoading ? <CircularProgress /> : `Save`}
                  </ButtonStyle>
                  <ButtonStyle
                    onClick={() => {
                      setOpenAdd(false);
                    }}
                    style={{ backgroundColor: "red", borderColor: "red" }}
                  >
                    Cancel
                  </ButtonStyle>
                </BoxModalBtn>
              </BoxModalStyle>
              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                open={snack}
                autoHideDuration={4000}
                onClose={handleCloseSnack}
              >
                <Alert severity="error">Un Expected error Happend !</Alert>
              </Snackbar>
            </Box>
          </form>
        </Modal>
      </BoxModal>
    </div>
  );
}
