import { useEffect, useState, React } from "react";
import { Box, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { Checkbox, Snackbar, Alert, MenuItem } from "@mui/material";
import styled from "styled-components";
import axios from "axios";
import TextField from "@mui/material/TextField";
import { useQuery, useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #eee",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const ErrorText = styled.p`
  color: red;
`;

const TypographyLabel = styled.h2`
  font-weight: bold;
  color: #31d703;
`;

const BoxModalStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  gap: 10px;
`;

const BoxModal = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
`;
const BoxModalFlex = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 35px;
  align-items: center;
`;
const BoxModalFlexNew = styled.div`
  display: flex;
  justify-content: space-between;
  /* margin: 40px; */
  align-items: center;
`;
const BoxModalFlexN = styled.div`
  display: flex;
  flex-direction: column;
  margin: -12px;
`;
const ButtonStyle = styled.button`
  width: 150px;
  height: 50px;
  background-color: #31d703;
  color: #eee;
  font-size: 16px;
  font-weight: bold;
  border-color: #31d703;
  border-radius: 5px;
`;

export default function EditUserModal({
  openUpdate,
  setOpenUpdate,
  currentRow,
}) {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("userStorage")
    ? JSON.parse(localStorage.getItem("userStorage")).AccessToken
    : null;
  const schema = yup.object().shape({
    DisplayName: yup
      .string("validation.invalid display name")
      .required("Display Name is required"),
    Username: yup
      .string("validation.invalid user name")
      .required("Username is required"),
    RoleID: yup
      .string("validation.invalid password")
      .required("Role is required"),
    BranchID: yup.string("validation.Branch ID").required("Branch is required"),
  });
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
  });
  const setValues = (obj) => {
    for (let i = 0; i < Object.keys(obj).length; i++) {
      setValue(Object.keys(obj)[i], obj[Object.keys(obj)[i]]);
    }
  };
  const [snack, setSnack] = useState(false);
  const { data: RoleData } = useQuery(
    "role_list",
    async () => {
      const data = await axios.get(
        "https://trackshipments.message-express.com/APIs/api/Roles/V1/GetAll",
        {
          headers: {
            CompanyID: "211801",
            AccessToken: `${accessToken}`,
          },
        }
      );
      return data.data.Data;
    },
    {
      onError: (error) => {
        if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized response
          localStorage.clear();
          navigate("/");
        }
      },
    }
  );
  const { data: BranchData } = useQuery(
    "branch_list",
    async () => {
      const data = await axios.get(
        "https://trackshipments.message-express.com/APIs/api/Branches/V1/GetAll",
        {
          headers: {
            CompanyID: "211801",
            AccessToken: `${accessToken}`,
          },
        }
      );
      return data.data.Data;
    },
    {
      onError: (error) => {
        if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized response
          localStorage.clear();
          navigate("/");
        }
      },
    }
  );
  const handleCloseSnack = () => {
    setSnack(false);
  };

  const registerHandler = (data) => {
    axios
      .post(
        `https://trackshipments.message-express.com/APIs/api/Users/V1/Update`,
        { ...data },
        {
          headers: {
            CompanyID: "211801",
            AccessToken: `${accessToken}`,
          },
        }
      )
      .then((res) => {
        queryClient.invalidateQueries("DataGridSignUp");
        setOpenUpdate(false);
        reset();
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          // Handle 401 Unauthorized response
          localStorage.clear();
          navigate("/");
        } else {
          setSnack(true);
        }
      });
  };
  useEffect(() => {
    setValues(currentRow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRow]);
  return (
    <div>
      <Modal
        open={openUpdate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleSubmit(registerHandler)}>
          <Box sx={style}>
            <BoxModalStyle>
              <Box>
                <TypographyLabel>Update User</TypographyLabel>
              </Box>
              <BoxModalStyle>
                <BoxModalFlex>
                  <BoxModalFlexN>
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Select Branch"
                      sx={{ width: "210px" }}
                      {...register("BranchID", {
                        required: "* Branch Name Is Required",
                      })}
                    >
                      {BranchData?.map((item) => (
                        <MenuItem
                          key={item.BranchID}
                          value={item.BranchID || ""}
                        >
                          {item.EnglishName}
                        </MenuItem>
                      ))}
                    </TextField>
                    <ErrorText>{errors.BranchID?.message}</ErrorText>
                  </BoxModalFlexN>
                  <BoxModalFlexN>
                    <TextField
                      id="outlined-basic"
                      label="User Name"
                      variant="outlined"
                      {...register("Username", {
                        required: "* username Is Required",
                      })}
                      sx={{ visibility: "hidden" }}
                    />
                    <ErrorText>{errors.Username?.message}</ErrorText>
                  </BoxModalFlexN>
                </BoxModalFlex>
                <BoxModalFlex>
                  <BoxModalFlexN>
                    <TextField
                      id="outlined-basic"
                      label="Display Name"
                      variant="outlined"
                      {...register("DisplayName", {
                        required: "* display Name Is Required",
                      })}
                    />
                    <ErrorText>{errors.DisplayName?.message}</ErrorText>
                  </BoxModalFlexN>
                  <BoxModalFlexN>
                    <TextField
                      id="outlined-basic"
                      label="User Name"
                      variant="outlined"
                      {...register("Username", {
                        required: "* username Is Required",
                      })}
                    />
                    <ErrorText>{errors.Username?.message}</ErrorText>
                  </BoxModalFlexN>
                </BoxModalFlex>
                <BoxModalFlex>
                  <BoxModalFlexNew>
                    <Typography>Is Active</Typography>
                    <Checkbox
                      name={"IsEnabled"}
                      defaultChecked={currentRow.IsEnabled}
                    />
                  </BoxModalFlexNew>
                  <BoxModalFlex>
                    <BoxModalFlexN>
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Select Role"
                        sx={{ width: "210px", marginLeft: "85px" }}
                        {...register("RoleID", {
                          required: "* RoleID Is Required",
                        })}
                      >
                        {RoleData?.map((item) => (
                          <MenuItem key={item.RoleID} value={item.RoleID || ""}>
                            {item.EnglishName}
                          </MenuItem>
                        ))}
                      </TextField>
                      <ErrorText>{errors.RoleID?.message}</ErrorText>
                    </BoxModalFlexN>
                  </BoxModalFlex>
                </BoxModalFlex>
              </BoxModalStyle>
              <BoxModal>
                <ButtonStyle type="submit">Update</ButtonStyle>
                <ButtonStyle
                  onClick={() => {
                    setOpenUpdate(false);
                  }}
                  style={{ backgroundColor: "red", borderColor: "red" }}
                >
                  Cancel
                </ButtonStyle>
              </BoxModal>
            </BoxModalStyle>
          </Box>
        </form>
      </Modal>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={snack}
        autoHideDuration={4000}
        onClose={handleCloseSnack}
      >
        <Alert severity="error">Un Expected error Happend !</Alert>
      </Snackbar>
    </div>
  );
}
