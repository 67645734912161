import { useState, React, useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import AddUserModal from "./AddUserModal";
import styled from "styled-components";
import Edit from "../../assets/images/edit.png";
import Delete from "../../assets/images/delete.png";
import Change from "../../assets/images/reset-password.png";
import EditUserModal from "./EditUserModal";
import DeleteUserModal from "./DeleteUserModal";
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import ResetPassword from "./ResetPassword";
import SideBar from "../sideBar/SideBar";

const BtnGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export default function DataGridSignUp() {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "All User";
    return () => {
      document.title = "Original Page Title";
    };
  }, []);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openReset, setOpenReset] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const columns = [
    { field: "UserID", headerName: "ID", width: 90 },
    {
      field: "DisplayName",
      headerName: "Display Name",
      width: 150,
      editable: false,
    },
    {
      field: "Username",
      headerName: "User Name",
      width: 150,
      editable: false,
    },
    {
      field: "IsEnabled",
      headerName: "Is Active",
      width: 125,
      minWidth: 90,
      maxWidth: 125,
      editable: false,
      Type: Checkbox,
      renderCell: ({ row }) => (
        <strong>
          {
            <Checkbox
              disabled
              sx={{ color: "#B00A70", "&.Mui-checked": { color: "#B00A70" } }}
              checked={row.IsEnabled ? true : false}
              checkedIcon={<CheckIcon />}
              icon={<ClearIcon />}
            ></Checkbox>
          }
        </strong>
      ),
      headerAlign: "left",
    },
    {
      field: "RoleEnglishName",
      headerName: "Role",
      width: 150,
      editable: false,
    },
    {
      field: "BranchEnglishName",
      headerName: "Branch",
      width: 150,
      editable: false,
    },
    {
      field: "Change Password",
      headerName: "Change Password",
      width: 150,
      editable: false,
      renderCell: ({ row }) => (
        <strong>
          <BtnGroup
            onClick={() => {
              setCurrentRow(row);
              setOpenReset(true);
            }}
          >
            <img src={Change} alt="reset password" width={40} />
          </BtnGroup>
        </strong>
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      width: 125,
      minWidth: 100,
      editable: false,
      renderCell: ({ row }) => (
        <strong>
          <BtnGroup>
            <BtnGroup
              onClick={() => {
                setOpenUpdate(true);
                setCurrentRow(row);
              }}
            >
              <img src={Edit} alt="edit " width={20} />
            </BtnGroup>
            <BtnGroup
              onClick={() => {
                setOpenDelete(true);
                setCurrentRow(row);
              }}
            >
              <img src={Delete} alt="edit " width={20} />
            </BtnGroup>
          </BtnGroup>
        </strong>
      ),
    },
  ];
  const { data, isLoading } = useQuery(
    "DataGridSignUp",
    async () => {
      const accessToken = localStorage.getItem("userStorage")
        ? JSON.parse(localStorage.getItem("userStorage")).AccessToken
        : null;
      const data = await axios.get(
        "https://trackshipments.message-express.com/APIs/api/Users/V1/GetAll",
        {
          headers: {
            CompanyID: "211801",
            AccessToken: `${accessToken}`,
          },
        }
      );
      return data.data.Data;
    },
    {
      onError: (error) => {
        if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized response
          localStorage.clear();
          navigate("/");
        }
      },
    }
  );

  return (
    <div>
      <SideBar />
      <Box sx={{ height: 450, width: "98%", margin: "0 auto" }}>
        <AddUserModal />
        <DataGrid
          rows={data || []}
          getRowId={(row) => {
            return row.UserID;
          }}
          hideFooterSelectedRowCount
          columns={columns}
          loading={isLoading}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 50,
              },
            },
          }}
          pageSizeOptions={[50]}
          checkboxSelection
          disableRowSelectionOnClick
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#94e785",
              color: "#000",
            },
          }}
        />
        <EditUserModal
          openUpdate={openUpdate}
          setOpenUpdate={setOpenUpdate}
          currentRow={currentRow}
          setCurrentRow={setCurrentRow}
        />
        <DeleteUserModal
          openDelete={openDelete}
          setOpenDelete={setOpenDelete}
          currentRow={currentRow}
          setCurrentRow={setCurrentRow}
        />
        <ResetPassword
          openReset={openReset}
          setOpenReset={setOpenReset}
          currentRow={currentRow}
          setCurrentRow={setCurrentRow}
        />
      </Box>
    </div>
  );
}
