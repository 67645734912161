import { useState, React } from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import AddShipmentModal from "./AddShipmentModal";
import styled from "styled-components";
import Edit from "../../assets/images/edit.png";
import Delete from "../../assets/images/delete.png";
import { useQuery } from "react-query";
import axios from "axios";
import UpdateShipment from "./UpdateShipment";
import DeleteShipment from "./DeleteShipment";
import AllPaymentDataGrid from "./AllPaymentDataGrid";
import AddPayment from "../../assets/images/wallet.png";
import AllPayment from "../../assets/images/bill.png";
import AddPaymentModal from "./AddPaymentModal";
import { useNavigate } from "react-router-dom";

const BtnGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export default function ShipmentDataGrid() {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Shipment Data";
    return () => {
      document.title = "Original Page Title";
    };
  }, []);
  const [currentRow, setCurrentRow] = useState({});
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openAllPayment, setOpenAllPayment] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const columns = [
    { field: "ShipmentID", headerName: "ShipmentID", width: 90 },
    {
      field: "AWB",
      headerName: "AWB",
      width: 150,
      editable: true,
    },
    {
      field: "AuditDate",
      headerName: "Shipment Date",
      width: 150,
      editable: true,
    },
    {
      field: "FromSender",
      headerName: "Shipment From",
      sortable: false,
      width: 160,
    },
    {
      field: "ToConsignee",
      headerName: "Shipment To",
      sortable: false,
      width: 160,
    },
    {
      field: "ToPhone",
      headerName: "To Phone",
      sortable: false,
      width: 160,
    },
    {
      field: "CountryEnglishName",
      headerName: "Country",
      sortable: false,
      width: 160,
    },
    {
      field: "Peices",
      headerName: "Pieces",
      sortable: false,
      width: 160,
    },
    {
      field: "TotalWeight",
      headerName: "Total Weight",
      sortable: false,
      width: 160,
    },
    {
      field: "DimentionalWeight",
      headerName: "dimension Weight",
      sortable: false,
      width: 160,
    },
    {
      field: "Reference",
      headerName: "Reference",
      sortable: false,
      width: 160,
    },
    {
      field: "Contents",
      headerName: "Contents",
      sortable: false,
      width: 160,
    },
    {
      field: "AgentEnglishName",
      headerName: "Agent",
      sortable: false,
      width: 160,
    },
    {
      field: "TotalValue",
      headerName: "TotalValue",
      sortable: false,
      width: 160,
    },
    {
      field: "InitialPayment",
      headerName: "Initial Payment",
      sortable: false,
      width: 160,
    },
    {
      field: "BranchEnglishName",
      headerName: "Branch",
      sortable: false,
      width: 160,
    },
    {
      field: "PaymentMethodEnglishName",
      headerName: "Payment Method",
      sortable: false,
      width: 160,
    },
    {
      field: "Notes",
      headerName: " Notes",
      sortable: false,
      width: 160,
    },
    {
      field: "Add Payment",
      headerName: " Add Payment",
      sortable: false,
      width: 160,
      renderCell: ({ row }) => (
        <strong>
          <BtnGroup
            onClick={() => {
              setOpenAdd(true);
              setCurrentRow(row);
            }}
          >
            <img src={AddPayment} alt="AddPayment " width={40} />
          </BtnGroup>
        </strong>
      ),
    },
    {
      field: "All Payment",
      headerName: " All Payment",
      sortable: false,
      width: 160,
      renderCell: ({ row }) => (
        <strong>
          <BtnGroup
            onClick={() => {
              setOpenAllPayment(true);
              setCurrentRow(row);
            }}
          >
            <img src={AllPayment} alt="AllPayment " width={40} />
          </BtnGroup>
        </strong>
      ),
    },

    {
      field: "Action",
      headerName: "Action",
      width: 125,
      minWidth: 100,
      editable: false,
      renderCell: ({ row }) => (
        <strong>
          <BtnGroup>
            <BtnGroup
              onClick={() => {
                setOpenUpdate(true);
                setCurrentRow(row);
              }}
            >
              <img src={Edit} alt="edit " width={20} />
            </BtnGroup>
            <BtnGroup
              onClick={() => {
                setOpenDelete(true);
                setCurrentRow(row);
              }}
            >
              <img src={Delete} alt="delete" width={20} />
            </BtnGroup>
          </BtnGroup>
        </strong>
      ),
    },
  ];
  const { data, isLoading } = useQuery(
    "ShipmentDataGrid",
    async () => {
      const accessToken = localStorage.getItem("userStorage")
        ? JSON.parse(localStorage.getItem("userStorage")).AccessToken
        : null;
      const pageSize = 5000;
      const pageNumber = 1;
      const data = await axios.get(
        `https://trackshipments.message-express.com/APIs/api/Shipments/V1/GetPage/${pageSize}/${pageNumber}`,
        {
          headers: {
            CompanyID: "211801",
            AccessToken: `${accessToken}`,
          },
        }
      );
      return data.data.Data;
    },
    {
      onError: (error) => {
        if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized response
          localStorage.clear();
          navigate("/");
        }
      },
    }
  );

  return (
    <Box sx={{ height: 450, width: "98%", margin: "0 auto" }}>
      <AddShipmentModal />
      <DataGrid
        rows={data || []}
        getRowId={(row) => {
          return row.ShipmentID;
        }}
        columns={columns}
        loading={isLoading}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
        }}
        pageSizeOptions={[50]}
        checkboxSelection
        disableRowSelectionOnClick
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#94e785",
            color: "#000",
          },
        }}
      />
      <UpdateShipment
        openUpdate={openUpdate}
        setOpenUpdate={setOpenUpdate}
        currentRow={currentRow}
        setCurrentRow={setCurrentRow}
      />
      <DeleteShipment
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        currentRow={currentRow}
        setCurrentRow={setCurrentRow}
      />
      <AddPaymentModal
        openAdd={openAdd}
        setOpenAdd={setOpenAdd}
        currentRow={currentRow}
        setCurrentRow={setCurrentRow}
      />
      <AllPaymentDataGrid
        openAllPayment={openAllPayment}
        setOpenAllPayment={setOpenAllPayment}
        currentRow={currentRow}
        setCurrentRow={setCurrentRow}
      />
    </Box>
  );
}
