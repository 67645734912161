import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { registerLicense } from "@syncfusion/ej2-base";

registerLicense(
  "ORg4AjUWIQA/Gnt2UVhhQlVHfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5SdkdiWXtccXxVRmNU"
);
const client = new QueryClient();
ReactDOM.render(
  <BrowserRouter>
    <QueryClientProvider client={client}>
      <App />
    </QueryClientProvider>
  </BrowserRouter>,

  document.getElementById("root")
);
