import { useEffect, useState, React } from "react";
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { Snackbar, Alert } from "@mui/material";
import styled from "styled-components";
import axios from "axios";
import TextField from "@mui/material/TextField";
import { useQuery, useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #eee",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};
const ErrorText = styled.p`
  color: red;
`;
const TypographyLabel = styled.h2`
  font-weight: bold;
  color: #31d703;
`;

const BoxModalStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  gap: 10px;
`;

const BoxModal = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
`;
const BoxModalFlex = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 35px;
  align-items: center;
`;
const BoxModalFlexN = styled.div`
  display: flex;
  flex-direction: column;
  margin: -12px;
`;

const ButtonStyle = styled.button`
  width: 150px;
  height: 50px;
  background-color: #31d703;
  color: #eee;
  font-size: 16px;
  font-weight: bold;
  border-color: #31d703;
  border-radius: 5px;
`;

export default function ResetPasswordModal({
  openReset,
  setOpenReset,
  currentRow,
}) {
  const navigate = useNavigate();
  const schema = yup.object().shape({
    NewPassword: yup
      .string("validation.invalid password")
      .required("New Password is required"),
    PasswordConfirm: yup
      .string("validation.invalid password")
      .required("Confirm Password is required")
      .oneOf([yup.ref("NewPassword"), null], "Passwords must match"),
  });
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
  });
  const setValues = (obj) => {
    for (let i = 0; i < Object.keys(obj).length; i++) {
      setValue(Object.keys(obj)[i], obj[Object.keys(obj)[i]]);
    }
  };
  const [snack, setSnack] = useState(false);
  const handleCloseSnack = () => {
    setSnack(false);
  };

  const registerHandler = (data) => {
    const accessToken = localStorage.getItem("userStorage")
      ? JSON.parse(localStorage.getItem("userStorage")).AccessToken
      : null;

    axios
      .post(
        `https://trackshipments.message-express.com/APIs/api/Users/V1/ResetPassword`,
        { ...data },
        {
          headers: {
            CompanyID: "211801",
            AccessToken: `${accessToken}`,
          },
        }
      )
      .then((res) => {
        queryClient.invalidateQueries("DataGridSignUp");
        setOpenReset(false);
        reset();
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          // Handle 401 Unauthorized response
          localStorage.clear();
          navigate("/");
        } else {
          setSnack(true);
        }
      });
  };
  useEffect(() => {
    setValues(currentRow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRow]);
  return (
    <div>
      <Modal
        open={openReset}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleSubmit(registerHandler)}>
          <Box sx={style}>
            <BoxModalStyle>
              <Box>
                <TypographyLabel>Reset Password</TypographyLabel>
              </Box>
              <BoxModalStyle>
                <BoxModalFlex>
                  <BoxModalFlexN>
                    <TextField
                      id="outlined-password-input"
                      label="New Password"
                      type="password"
                      {...register("NewPassword", {
                        required: "* Password Is Required",
                      })}
                    />
                    <ErrorText>{errors.NewPassword?.message}</ErrorText>
                  </BoxModalFlexN>
                  <BoxModalFlexN>
                    <TextField
                      id="outlined-password-input"
                      label="Confirm Password"
                      type="password"
                      {...register("PasswordConfirm", {
                        required: "* Password Is Required",
                      })}
                    />
                    <ErrorText>{errors.PasswordConfirm?.message}</ErrorText>
                  </BoxModalFlexN>
                </BoxModalFlex>
              </BoxModalStyle>
              <BoxModal>
                <ButtonStyle type="submit">Reset </ButtonStyle>
                <ButtonStyle
                  onClick={() => {
                    setOpenReset(false);
                  }}
                  style={{ backgroundColor: "red", borderColor: "red" }}
                >
                  Cancel
                </ButtonStyle>
              </BoxModal>
            </BoxModalStyle>
          </Box>
        </form>
      </Modal>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={snack}
        autoHideDuration={4000}
        onClose={handleCloseSnack}
      >
        <Alert severity="error">Un Expected error Happend !</Alert>
      </Snackbar>
    </div>
  );
}
