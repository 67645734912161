import { useRef, React } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Toolbar,
  ExcelExport,
  Group,
  Filter,
  Selection,
  Sort,
  Page,
} from "@syncfusion/ej2-react-grids";
import axios from "axios";
import { useQuery } from "react-query";
import { Box } from "@mui/material";
import SideBar from "../sideBar/SideBar";
import { useNavigate } from "react-router-dom";

export default function InvoiceDataGrid() {
  const navigate = useNavigate();
  const gridInstance = useRef(null);
  const toolbarClick = (args) => {
    switch (args.item.text) {
      case "Excel Export":
        gridInstance.current.excelExport();
        break;
      case "CSV Export":
        gridInstance.current.csvExport();
        break;
    }
  };
  const { data, isLoading } = useQuery(
    "ShipmentDataGrid",
    async () => {
      const accessToken = localStorage.getItem("userStorage")
        ? JSON.parse(localStorage.getItem("userStorage")).AccessToken
        : null;
      const pageSize = 50000;
      const pageNumber = 1;
      const data = await axios.get(
        `https://trackshipments.message-express.com/APIs/api/Shipments/V1/GetPage/${pageSize}/${pageNumber}`,

        {
          headers: {
            CompanyID: "211801",
            AccessToken: `${accessToken}`,
          },
        }
      );
      return data.data.Data;
    },
    {
      onError: (error) => {
        if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized response
          localStorage.clear();
          navigate("/");
        }
      },
    }
  );
  const toolbarOptions = ["ExcelExport", "CsvExport"];
  return (
    <div className="control-pane">
      <SideBar />
      <Box
        sx={{
          height: 450,
          width: "98%",
          margin: "0 auto",
        }}
        className="col-lg-9 control-section"
      >
        <GridComponent
          dataSource={data}
          toolbar={toolbarOptions}
          ref={gridInstance}
          selectionSettings={{ type: "checkbox" }}
          filterSettings={{ type: "Excel" }}
          allowSorting
          allowExcelExport
          allowPdfExport
          enableStickyHeader
          allowFiltering
          allowGrouping
          toolbarClick={toolbarClick}
        >
          <ColumnsDirective>
            <ColumnDirective width={70} type="checkbox"></ColumnDirective>
            <ColumnDirective
              headerText="ShipmentID"
              field="ShipmentID"
              width="140"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              field="AWB"
              headerText="AWB"
              textAlign="Center"
              width="150"
            ></ColumnDirective>
            <ColumnDirective
              field="AuditDate"
              headerText="Shipment Date"
              format="yMd"
              width="180"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              field="FromSender"
              headerText="Ship From"
              width="130"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              field="ToConsignee"
              headerText="Ship To"
              width="130"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              field="ToPhone"
              headerText="To Phone"
              width="120"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              field="CountryEnglishName"
              headerText="Country"
              width="130"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              field="Peices"
              width="120"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              headerText="Weight"
              field="TotalWeight"
              width="120"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              headerText="Dimension"
              field="DimentionalWeight"
              width="130"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              headerText="Reference"
              field="Reference"
              width="130"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              headerText="Contents"
              field="Contents"
              width="120"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              headerText="Agent"
              field="AgentEnglishName"
              width="120"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              headerText="Total Value"
              field="TotalValue"
              width="140"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              headerText="Initial Payment"
              field="InitialPayment"
              width="150"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              field="BranchEnglishName"
              headerText="Branch"
              width="160"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              headerText="Payment Method"
              field="PaymentMethodEnglishName"
              width="170"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              headerText="Notes"
              field="Notes"
              width="150"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              headerText="Username"
              field="Username"
              width="150"
              textAlign="Center"
            ></ColumnDirective>
          </ColumnsDirective>
          <Inject
            services={[
              Toolbar,
              ExcelExport,
              Group,
              Page,
              Filter,
              Selection,
              Sort,
            ]}
          />
        </GridComponent>
      </Box>
    </div>
  );
}
