import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import axios from "axios";
import { useForm } from "react-hook-form";
import Delete from "../../assets/images/delete.png";
import DeleteAllPayment from "./DeleteAllPayment";
import { useNavigate } from "react-router-dom";

const BtnGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  height: 450,
  bgcolor: "background.paper",
  border: "2px solid #eee",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};
const ButtonStyle = styled.button`
  width: 150px;
  height: 50px;
  background-color: #31d703;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border-color: #31d703;
  border-radius: 5px;
  margin-top: 10px;
`;

export default function AddPaymentModal({
  openAllPayment,
  setOpenAllPayment,
  currentRow,
  setCurrentRow,
}) {
  const navigate = useNavigate();
  const columns = [
    { field: "PaymentID", headerName: "PaymentID", width: 90 },
    {
      field: "PaymentMethodEnglishName",
      headerName: "Payment Method",
      width: 150,
      editable: false,
    },
    {
      field: "Value",
      headerName: "Value",
      width: 90,
      editable: false,
    },
    {
      field: "Username",
      headerName: "User name",
      width: 110,
      editable: false,
    },
    {
      field: "Notes",
      headerName: "Notes",
      width: 170,
      editable: false,
    },
    {
      field: "Action",
      headerName: "Action",
      width: 125,
      minWidth: 100,
      editable: false,
      renderCell: ({ row }) => (
        <strong>
          <BtnGroup
            onClick={() => {
              setOpenDeleteAllPayment(true);
              setCurrentRow(row);
            }}
          >
            <img src={Delete} alt="delete" width={20} />
          </BtnGroup>
        </strong>
      ),
    },
  ];

  const { setValue } = useForm({
    mode: "onTouched",
  });
  const setValues = (obj) => {
    for (let i = 0; i < Object.keys(obj).length; i++) {
      setValue(Object.keys(obj)[i], obj[Object.keys(obj)[i]]);
    }
  };
  const [openDeleteAllPayment, setOpenDeleteAllPayment] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (currentRow && currentRow.ShipmentID) {
      setValues(currentRow);
      const fetchData = async () => {
        try {
          const accessToken = localStorage.getItem("userStorage")
            ? JSON.parse(localStorage.getItem("userStorage")).AccessToken
            : null;
          const response = await axios.get(
            `https://trackshipments.message-express.com/APIs/api/Payments/V1/GetByShipmentID/${currentRow?.ShipmentID}`,
            {
              headers: {
                CompanyID: "211801",
                AccessToken: `${accessToken}`,
              },
            }
          );
          setData(response.data.Data);
        } catch (error) {
          console.error("Error fetching data:", error);

          if (error.response && error.response.status === 401) {
            // Handle 401 Unauthorized response
            localStorage.clear();
            navigate("/");
          }
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRow]);

  return (
    <div>
      <Modal
        open={openAllPayment}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={style}>
            <Box
              sx={{
                color: "green",
                display: "flex",
                justifyContent: "center",
                fontSize: "22px",
                fontWeight: "bold",
              }}
            >
              All Payment
            </Box>
            <Box sx={{ height: 300, width: "100%", margin: "0 auto" }}>
              <DataGrid
                rows={data}
                getRowId={(row) => row.PaymentID}
                columns={columns}
                loading={isLoading}
                pageSize={5}
                checkboxSelection
                disableRowSelectionOnClick
                sx={{
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#94e785",
                    color: "#000",
                  },
                }}
              />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <ButtonStyle
                onClick={() => {
                  setOpenAllPayment(false);
                }}
                style={{ backgroundColor: "red", borderColor: "red" }}
              >
                Cancel
              </ButtonStyle>
            </Box>
          </Box>
          <DeleteAllPayment
            openDeleteAllPayment={openDeleteAllPayment}
            setOpenDeleteAllPayment={setOpenDeleteAllPayment}
            currentRow={currentRow}
            setCurrentRow={setCurrentRow}
          />
        </Box>
      </Modal>
    </div>
  );
}
