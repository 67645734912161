import * as React from "react";
import { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import { Box, CircularProgress, MenuItem } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useQueryClient, useQuery } from "react-query";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import SideBar from "../sideBar/SideBar";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  height: 650,
  bgcolor: "background.paper",
  border: "2px solid #eee",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const TypographyLabel = styled.h2`
  font-weight: bold;
  color: #31d703;
`;

const BoxModalStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  gap: 10px;
`;
const StyleFlexColum = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: -25px;
`;
const ErrorText = styled.p`
  color: red;
`;
const BoxModal = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
`;
const BoxModalFlex = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
`;

const ButtonStyle = styled.button`
  width: 150px;
  height: 50px;
  background-color: #31d703;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border-color: #31d703;
  border-radius: 5px;
`;
export default function AddShipmentModal() {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Add Shipment";
    return () => {
      document.title = "Original Page Title";
    };
  }, []);
  const accessToken = localStorage.getItem("userStorage")
    ? JSON.parse(localStorage.getItem("userStorage")).AccessToken
    : null;
  const schema = yup.object().shape({
    ShipmentID: yup
      .number("validation.invalid_ShipmentID")
      .required("ShipmentID is required"),
    AWB: yup.string("validation.invalid AWB").required("AWB Name is required"),
    TransDate: yup
      .date("validation.invalid TransDate")
      .required("TransDate is required"),
    FromSender: yup
      .string("validation.invalid FromSender")
      .required("FromSender is required"),
    ToConsignee: yup
      .string("validation.invalid ToConsignee")
      .required(" ToConsignee is required"),
    ToPhone: yup
      .string("validation.invalid ToPhone")
      .required("ToPhone is required"),
    CountryCode: yup
      .string("validation.invalid CountryCode")
      .required("CountryCode is required"),
    Peices: yup
      .number("validation.invalid Peices")
      .required("Pieces is required"),
    TotalWeight: yup
      .number("validation.invalid TotalWeight")
      .required("TotalWeight is required"),
    DimentionalWeight: yup
      .number("validation.invalid DimentionalWeight")
      .required("Dimension Weight is required"),
    Reference: yup
      .string("validation.invalid Reference")
      .required("Reference is required"),
    Contents: yup
      .string("validation.invalid Contents")
      .required("Contents is required"),
    AgentID: yup
      .number("validation.invalid AgentID")
      .required("Agent is required"),
    TotalValue: yup
      .number("validation.invalid TotalValue")
      .required("TotalValue is required"),
    InitialPayment: yup
      .number("validation.invalid InitialPayment")
      .required("InitialPayment is required"),
    BranchID: yup
      .number("validation.invalid BranchID")
      .required("Branch is required"),
    PaymentMethodID: yup
      .string("validation.invalid PaymentMethodID")
      .required("Payment Method is required"),
  });
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
  });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const queryClient = useQueryClient();
  const { data: ToCityData } = useQuery(
    "city_list",
    async () => {
      const ToCityData = await axios.get(
        "https://trackshipments.message-express.com/APIs/api/Countries/V1/GetList",
        {
          headers: {
            CompanyID: "211801",
            AccessToken: `${accessToken}`,
          },
        }
      );
      return ToCityData.data.Data;
    },
    {
      onError: (error) => {
        if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized response
          localStorage.clear();
          navigate("/");
        }
      },
    }
  );
  const { data: PaymentData } = useQuery(
    "payment_list",
    async () => {
      const PaymentData = await axios.get(
        "https://trackshipments.message-express.com/APIs/api/PaymentMethods/V1/GetAll",
        {
          headers: {
            CompanyID: "211801",
            AccessToken: `${accessToken}`,
          },
        }
      );
      return PaymentData.data.Data;
    },
    {
      onError: (error) => {
        if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized response
          localStorage.clear();
          navigate("/");
        }
      },
    }
  );
  const { data: AgentsData } = useQuery(
    "agents_list",
    async () => {
      const AgentsData = await axios.get(
        "https://trackshipments.message-express.com/APIs/api/Agents/V1/GetAll",
        {
          headers: {
            CompanyID: "211801",
            AccessToken: `${accessToken}`,
          },
        }
      );
      return AgentsData.data.Data;
    },
    {
      onError: (error) => {
        if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized response
          localStorage.clear();
          navigate("/");
        }
      },
    }
  );
  const { data: BranchesData } = useQuery(
    "branches_list",
    async () => {
      const BranchesData = await axios.get(
        "https://trackshipments.message-express.com/APIs/api/Branches/V1/GetAll",
        {
          headers: {
            CompanyID: "211801",
            AccessToken: `${accessToken}`,
          },
        }
      );
      return BranchesData.data.Data;
    },
    {
      onError: (error) => {
        if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized response
          localStorage.clear();
          navigate("/");
        }
      },
    }
  );
  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  async function regeisterHandler(data) {
    const shipmentSchema = {
      ShipmentID: 0,
      AWB: "",
      TransDate: "",
      FromSender: "",
      ToConsignee: "",
      ToPhone: "",
      CountryCode: "",
      Peices: 0,
      TotalWeight: 0,
      DimentionalWeight: 0,
      Reference: "",
      Contents: "",
      AgentID: 0,
      TotalValue: 0,
      InitialPayment: 0,
      BranchID: 0,
      PaymentMethodID: 0,
      Notes: "",
    };
    try {
      setDisable(true);
      setIsLoading(true);
      const response = await axios.post(
        "https://trackshipments.message-express.com/APIs/api/Shipments/V1/Insert",
        {
          ...shipmentSchema,
          ...data,
        },
        {
          headers: {
            CompanyID: "211801",
            AccessToken: `${accessToken}`,
          },
        }
      );
      queryClient.invalidateQueries("ShipmentDataGrid");
      handleClose();
      setIsLoading(false);
      setDisable(false);
      reset();
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status === 401) {
        // Handle 401 Unauthorized response
        localStorage.clear();
        navigate("/");
      } else {
        setIsLoading(false);
        setDisable(false);
      }
    }
  }
  return (
    <div>
      <SideBar />
      <BoxModal>
        <TypographyLabel>All Shipment</TypographyLabel>
        <ButtonStyle onClick={handleOpen}>Add Shipment</ButtonStyle>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <form onSubmit={handleSubmit(regeisterHandler)}>
              <BoxModalStyle>
                <Box>
                  <TypographyLabel>Add Shipment</TypographyLabel>
                </Box>
                <BoxModalStyle>
                  <BoxModalFlex>
                    <StyleFlexColum>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DateField"]}>
                          <DateField
                            label="Shipment Date"
                            value=""
                            onChange={(date) => {
                              setValue("TransDate", date);
                            }}
                            format="DD-MM-YYYY"
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      <ErrorText>{errors.TransDate?.message}</ErrorText>
                    </StyleFlexColum>
                    <StyleFlexColum>
                      <TextField
                        id="outlined-basic"
                        label="Shipment From"
                        variant="outlined"
                        {...register("FromSender", {
                          required: "* FromSender Is Required",
                        })}
                      />
                      <ErrorText>{errors.FromSender?.message}</ErrorText>
                    </StyleFlexColum>
                    <StyleFlexColum>
                      <TextField
                        id="outlined-basic"
                        label="Shipment ID"
                        defaultValue={0}
                        style={{ visibility: "hidden" }}
                        variant="outlined"
                        {...register("ShipmentID", {
                          required: "* ShipmentID Is Required",
                        })}
                      />
                      <ErrorText>{errors.ShipmentID?.message}</ErrorText>
                    </StyleFlexColum>
                  </BoxModalFlex>
                  <BoxModalFlex>
                    <StyleFlexColum>
                      <TextField
                        id="outlined-basic"
                        label="Shipment To"
                        variant="outlined"
                        {...register("ToConsignee", {
                          required: "* ToConsignee Is Required",
                        })}
                      />
                      <ErrorText>{errors.ToConsignee?.message}</ErrorText>
                    </StyleFlexColum>
                    <StyleFlexColum>
                      <TextField
                        id="outlined-basic"
                        label="To Phone"
                        type="number"
                        variant="outlined"
                        {...register("ToPhone", {
                          required: "* ToPhone Is Required",
                        })}
                      />
                      <ErrorText>{errors.ToPhone?.message}</ErrorText>
                    </StyleFlexColum>
                    <StyleFlexColum>
                      <TextField
                        id="outlined-select-currency"
                        select
                        autoComplete=""
                        label="To City"
                        sx={{ width: "220px" }}
                        {...register("CountryCode", {
                          required: "* Country Is Required",
                        })}
                      >
                        {ToCityData?.map((item) => (
                          <MenuItem
                            key={item.CountryCode}
                            value={item.CountryCode || ""}
                          >
                            {item.EnglishName}
                          </MenuItem>
                        ))}
                      </TextField>
                      <ErrorText>{errors.CountryCode?.message}</ErrorText>
                    </StyleFlexColum>
                  </BoxModalFlex>
                  <BoxModalFlex>
                    <StyleFlexColum>
                      <TextField
                        id="outlined-basic"
                        label="Waybill Number"
                        variant="outlined"
                        {...register("AWB", {
                          required: "* AWB Is Required",
                        })}
                      />
                      <ErrorText>{errors.AWB?.message}</ErrorText>
                    </StyleFlexColum>
                    <StyleFlexColum>
                      <TextField
                        id="outlined-basic"
                        label="Number Of Pieces"
                        type="number"
                        variant="outlined"
                        {...register("Peices", {
                          required: "* Peices Is Required",
                        })}
                      />
                      <ErrorText>{errors.Peices?.message}</ErrorText>
                    </StyleFlexColum>
                    <StyleFlexColum>
                      <TextField
                        id="outlined-basic"
                        label="Total Weight"
                        variant="outlined"
                        {...register("TotalWeight", {
                          required: "* TotalWeight Is Required",
                        })}
                      />
                      <ErrorText>{errors.TotalWeight?.message}</ErrorText>
                    </StyleFlexColum>
                  </BoxModalFlex>
                  <BoxModalFlex>
                    <StyleFlexColum>
                      <TextField
                        id="outlined-basic"
                        label="Dimensional"
                        variant="outlined"
                        {...register("DimentionalWeight", {
                          required: "* DimentionalWeight Is Required",
                        })}
                      />
                      <ErrorText>{errors.DimentionalWeight?.message}</ErrorText>
                    </StyleFlexColum>
                    <StyleFlexColum>
                      <TextField
                        id="outlined-basic"
                        label="Reference"
                        variant="outlined"
                        {...register("Reference", {
                          required: "* Reference Is Required",
                        })}
                      />
                      <ErrorText>{errors.Reference?.message}</ErrorText>
                    </StyleFlexColum>
                    <StyleFlexColum>
                      <TextField
                        id="outlined-basic"
                        label="Content"
                        variant="outlined"
                        {...register("Contents", {
                          required: "* Contents Is Required",
                        })}
                      />
                      <ErrorText>{errors.Contents?.message}</ErrorText>
                    </StyleFlexColum>
                  </BoxModalFlex>
                  <BoxModalFlex>
                    <StyleFlexColum>
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Agents"
                        sx={{ width: "220px" }}
                        {...register("AgentID", {
                          required: "* AgentID Is Required",
                        })}
                      >
                        {AgentsData?.map((item) => (
                          <MenuItem
                            key={item.AgentID}
                            value={item.AgentID || ""}
                          >
                            {item.EnglishName}
                          </MenuItem>
                        ))}
                      </TextField>
                      <ErrorText>{errors.AgentID?.message}</ErrorText>
                    </StyleFlexColum>
                    <StyleFlexColum>
                      <TextField
                        id="outlined-basic"
                        label="Collected Value"
                        variant="outlined"
                        type="number"
                        {...register("InitialPayment", {
                          required: "* InitialPayment Is Required",
                        })}
                      />
                      <ErrorText>{errors.InitialPayment?.message}</ErrorText>
                    </StyleFlexColum>
                    <StyleFlexColum>
                      <TextField
                        id="outlined-basic"
                        label="Balance"
                        type="number"
                        variant="outlined"
                        {...register("TotalValue", {
                          required: "* TotalValue Is Required",
                        })}
                      />
                      <ErrorText>{errors.TotalValue?.message}</ErrorText>
                    </StyleFlexColum>
                  </BoxModalFlex>
                  <BoxModalFlex>
                    <StyleFlexColum>
                      <TextField
                        id="outlined-basic"
                        label="Balance"
                        type="number"
                        variant="outlined"
                        {...register("TotalValue", {
                          required: "* TotalValue Is Required",
                        })}
                      />
                      <ErrorText>{errors.TotalValue?.message}</ErrorText>
                    </StyleFlexColum>
                    <StyleFlexColum>
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Payment Method"
                        sx={{ width: "220px" }}
                        {...register("PaymentMethodID", {
                          required: "* PaymentMethod Is Required",
                        })}
                      >
                        {PaymentData?.map((item) => (
                          <MenuItem
                            key={item.PaymentMethodID}
                            value={item.PaymentMethodID || ""}
                          >
                            {item.EnglishName}
                          </MenuItem>
                        ))}
                      </TextField>
                      <ErrorText>{errors.PaymentMethodID?.message}</ErrorText>
                    </StyleFlexColum>
                    <TextField
                      id="outlined-basic"
                      label="Notes"
                      variant="outlined"
                      {...register("Notes", {
                        required: "* Notes Is Required",
                      })}
                    />
                  </BoxModalFlex>
                  <BoxModalFlex>
                    <StyleFlexColum>
                      <TextField
                        id="outlined-select-currency"
                        select
                        defaultValue={0}
                        label="Branch Name"
                        sx={{ width: "220px", display: "none" }}
                        {...register("BranchID", {
                          required: "* Branch Is Required",
                        })}
                      >
                        {BranchesData?.map((item) => (
                          <MenuItem
                            key={item.BranchID}
                            value={item.BranchID || ""}
                          >
                            {item.EnglishName}
                          </MenuItem>
                        ))}
                      </TextField>
                      <ErrorText>{errors.BranchID?.message}</ErrorText>
                    </StyleFlexColum>
                  </BoxModalFlex>
                </BoxModalStyle>
                <BoxModal>
                  <ButtonStyle type="submit">
                    {isLoading ? <CircularProgress /> : `Save`}
                  </ButtonStyle>
                  <ButtonStyle
                    onClick={handleClose}
                    style={{ backgroundColor: "red", borderColor: "red" }}
                  >
                    Cancel
                  </ButtonStyle>
                </BoxModal>
              </BoxModalStyle>
            </form>
          </Box>
        </Modal>
      </BoxModal>
    </div>
  );
}
