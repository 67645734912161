import "./App.css";
import { Route, Routes } from "react-router-dom";
import DataGridSignUp from "./components/signUp/DataGridSignUp";
import ShipmentDataGrid from "./components/shipmentData/ShipmentDataGrid";
import SignIn from "./components/signIn/SignIn";
import Main from "./Main";
import ReportShipment from "./components/report/ReportShipment";
import AllPaymentDataGrid from "./components/shipmentData/AllPaymentDataGrid";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/sign-up" element={<DataGridSignUp />} />
        <Route path="/shipment-data-grid" element={<ShipmentDataGrid />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/all-payment" element={<AllPaymentDataGrid />} />
        <Route path="/report-shipments" element={<ReportShipment />} />
      </Routes>
    </div>
  );
}

export default App;
