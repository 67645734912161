import { useEffect, useState, React } from "react";
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { Snackbar, Alert, MenuItem, CircularProgress } from "@mui/material";
import styled from "styled-components";
import axios from "axios";
import TextField from "@mui/material/TextField";
import { useQuery, useQueryClient } from "react-query";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

const StyleFlexColum = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: -25px;
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  border: "2px solid #eee",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const ErrorText = styled.p`
  color: red;
`;

const TypographyLabel = styled.h2`
  font-weight: bold;
  color: #31d703;
`;

const BoxModalStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  gap: 10px;
`;

const BoxModal = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
`;
const BoxModalFlex = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 35px;
  align-items: center;
`;

const ButtonStyle = styled.button`
  width: 150px;
  height: 50px;
  background-color: #31d703;
  color: #eee;
  font-size: 16px;
  font-weight: bold;
  border-color: #31d703;
  border-radius: 5px;
`;

export default function UpdateModal({ openUpdate, setOpenUpdate, currentRow }) {
  const navigate = useNavigate();
  const schema = yup.object().shape({
    ShipmentID: yup
      .number("validation.invalid_ShipmentID")
      .required("ShipmentID is required"),
    AWB: yup.string("validation.invalid AWB").required("AWB Name is required"),
    TransDate: yup
      .date("validation.invalid TransDate")
      .required("TransDate is required"),
    FromSender: yup
      .string("validation.invalid FromSender")
      .required("FromSender is required"),
    ToConsignee: yup
      .string("validation.invalid ToConsignee")
      .required(" ToConsignee is required"),
    ToPhone: yup
      .string("validation.invalid ToPhone")
      .required("ToPhone is required"),
    CountryCode: yup
      .string("validation.invalid CountryCode")
      .required("CountryCode is required"),
    Peices: yup
      .number("validation.invalid Peices")
      .required("Pieces is required"),
    TotalWeight: yup
      .number("validation.invalid TotalWeight")
      .required("TotalWeight is required"),
    DimentionalWeight: yup
      .number("validation.invalid DimentionalWeight")
      .required("Dimension Weight is required"),
    Reference: yup
      .string("validation.invalid Reference")
      .required("Reference is required"),
    Contents: yup
      .string("validation.invalid Contents")
      .required("Contents is required"),
    AgentID: yup
      .number("validation.invalid AgentID")
      .required("Agent is required"),
    TotalValue: yup
      .number("validation.invalid TotalValue")
      .required("TotalValue is required"),
    InitialPayment: yup
      .number("validation.invalid InitialPayment")
      .required("InitialPayment is required"),
    BranchID: yup
      .number("validation.invalid BranchID")
      .required("Branch is required"),
    PaymentMethodID: yup
      .string("validation.invalid PaymentMethodID")
      .required("Payment Method is required"),
  });
  const accessToken = localStorage.getItem("userStorage")
    ? JSON.parse(localStorage.getItem("userStorage")).AccessToken
    : null;
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
  });
  const setValues = (obj) => {
    for (let i = 0; i < Object.keys(obj).length; i++) {
      setValue(Object.keys(obj)[i], obj[Object.keys(obj)[i]]);
    }
  };
  const [snack, setSnack] = useState(false);
  const { data: ToCityData } = useQuery(
    "city_list",
    async () => {
      const ToCityData = await axios.get(
        "https://trackshipments.message-express.com/APIs/api/Countries/V1/GetList",
        {
          headers: {
            CompanyID: "211801",
            AccessToken: `${accessToken}`,
          },
        }
      );
      return ToCityData.data.Data;
    },
    {
      onError: (error) => {
        if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized response
          localStorage.clear();
          navigate("/");
        }
      },
    }
  );
  const { data: PaymentData } = useQuery(
    "payment_list",
    async () => {
      const PaymentData = await axios.get(
        "https://trackshipments.message-express.com/APIs/api/PaymentMethods/V1/GetAll",
        {
          headers: {
            CompanyID: "211801",
            AccessToken: `${accessToken}`,
          },
        }
      );
      return PaymentData.data.Data;
    },
    {
      onError: (error) => {
        if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized response
          localStorage.clear();
          navigate("/");
        }
      },
    }
  );
  const { data: AgentsData } = useQuery(
    "agents_list",
    async () => {
      const AgentsData = await axios.get(
        "https://trackshipments.message-express.com/APIs/api/Agents/V1/GetAll",
        {
          headers: {
            CompanyID: "211801",
            AccessToken: `${accessToken}`,
          },
        }
      );
      return AgentsData.data.Data;
    },
    {
      onError: (error) => {
        if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized response
          localStorage.clear();
          navigate("/");
        }
      },
    }
  );
  const { data: BranchesData } = useQuery(
    "branches_list",
    async () => {
      const BranchesData = await axios.get(
        "https://trackshipments.message-express.com/APIs/api/Branches/V1/GetAll",
        {
          headers: {
            CompanyID: "211801",
            AccessToken: `${accessToken}`,
          },
        }
      );
      return BranchesData.data.Data;
    },
    {
      onError: (error) => {
        if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized response
          localStorage.clear();
          navigate("/");
        }
      },
    }
  );
  const handleCloseSnack = () => {
    setSnack(false);
  };
  const [isLoading, setIsLoading] = useState(false);
  const registerHandler = (data) => {
    axios
      .post(
        `https://trackshipments.message-express.com/APIs/api/Shipments/V1/Update`,
        { ...data },
        {
          headers: {
            CompanyID: "211801",
            AccessToken: `${accessToken}`,
          },
        }
      )
      .then((res) => {
        queryClient.invalidateQueries("ShipmentDataGrid");
        setOpenUpdate(false);
        reset();
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          // Handle 401 Unauthorized response
          localStorage.clear();
          navigate("/"); // Assuming you have imported the navigate function from your router library
        } else {
          setSnack(true);
        }
      });
  };
  useEffect(() => {
    setValues(currentRow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRow]);
  return (
    <div>
      <Modal
        open={openUpdate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit(registerHandler)}>
            <BoxModalStyle>
              <Box>
                <TypographyLabel>Add Shipment</TypographyLabel>
              </Box>
              <BoxModalStyle>
                <BoxModalFlex>
                  <StyleFlexColum>
                    <TextField
                      id="outlined-basic"
                      label="Shipment ID"
                      variant="outlined"
                      {...register("ShipmentID", {
                        required: "* ShipmentID Is Required",
                      })}
                    />
                    <ErrorText>{errors.ShipmentID?.message}</ErrorText>
                  </StyleFlexColum>
                  <StyleFlexColum>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DateField"]}>
                        <DateField
                          label="Shipment Date"
                          value=""
                          onChange={(date) => {
                            setValue("TransDate", date);
                          }}
                          format="YYYY-MM-DD"
                          {...register("TransDate", {
                            required: "* TransDate Is Required",
                          })}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    <ErrorText>{errors.TransDate?.message}</ErrorText>
                  </StyleFlexColum>
                  <StyleFlexColum>
                    <TextField
                      id="outlined-basic"
                      label="Shipment From"
                      variant="outlined"
                      {...register("FromSender", {
                        required: "* FromSender Is Required",
                      })}
                    />
                    <ErrorText>{errors.FromSender?.message}</ErrorText>
                  </StyleFlexColum>
                </BoxModalFlex>
                <BoxModalFlex>
                  <StyleFlexColum>
                    <TextField
                      id="outlined-basic"
                      label="Shipment To"
                      variant="outlined"
                      {...register("ToConsignee", {
                        required: "* ToConsignee Is Required",
                      })}
                    />
                    <ErrorText>{errors.ToConsignee?.message}</ErrorText>
                  </StyleFlexColum>
                  <StyleFlexColum>
                    <TextField
                      id="outlined-basic"
                      label="To Phone"
                      type="number"
                      variant="outlined"
                      {...register("ToPhone", {
                        required: "* ToPhone Is Required",
                      })}
                    />
                    <ErrorText>{errors.ToPhone?.message}</ErrorText>
                  </StyleFlexColum>
                  <StyleFlexColum>
                    <TextField
                      id="outlined-select-currency"
                      select
                      autoComplete=""
                      label="To City"
                      sx={{ width: "220px" }}
                      {...register("CountryCode", {
                        required: "* Country Is Required",
                      })}
                    >
                      {ToCityData?.map((item) => (
                        <MenuItem
                          key={item.CountryCode}
                          value={item.CountryCode || ""}
                        >
                          {item.EnglishName}
                        </MenuItem>
                      ))}
                    </TextField>
                    <ErrorText>{errors.CountryCode?.message}</ErrorText>
                  </StyleFlexColum>
                </BoxModalFlex>
                <BoxModalFlex>
                  <StyleFlexColum>
                    <TextField
                      id="outlined-basic"
                      label="Waybill Number"
                      variant="outlined"
                      {...register("AWB", {
                        required: "* AWB Is Required",
                      })}
                    />
                    <ErrorText>{errors.AWB?.message}</ErrorText>
                  </StyleFlexColum>
                  <StyleFlexColum>
                    <TextField
                      id="outlined-basic"
                      label="Number Of Pieces"
                      variant="outlined"
                      {...register("Peices", {
                        required: "* Peices Is Required",
                      })}
                    />
                    <ErrorText>{errors.Peices?.message}</ErrorText>
                  </StyleFlexColum>
                  <StyleFlexColum>
                    <TextField
                      id="outlined-basic"
                      label="Total Weight"
                      variant="outlined"
                      {...register("TotalWeight", {
                        required: "* TotalWeight Is Required",
                      })}
                    />
                    <ErrorText>{errors.TotalWeight?.message}</ErrorText>
                  </StyleFlexColum>
                </BoxModalFlex>
                <BoxModalFlex>
                  <StyleFlexColum>
                    <TextField
                      id="outlined-basic"
                      label="Dimensional"
                      variant="outlined"
                      {...register("DimentionalWeight", {
                        required: "* DimentionalWeight Is Required",
                      })}
                    />
                    <ErrorText>{errors.DimentionalWeight?.message}</ErrorText>
                  </StyleFlexColum>
                  <StyleFlexColum>
                    <TextField
                      id="outlined-basic"
                      label="Reference"
                      variant="outlined"
                      {...register("Reference", {
                        required: "* Reference Is Required",
                      })}
                    />
                    <ErrorText>{errors.Reference?.message}</ErrorText>
                  </StyleFlexColum>
                  <StyleFlexColum>
                    <TextField
                      id="outlined-basic"
                      label="Content"
                      variant="outlined"
                      {...register("Contents", {
                        required: "* Contents Is Required",
                      })}
                    />
                    <ErrorText>{errors.Contents?.message}</ErrorText>
                  </StyleFlexColum>
                </BoxModalFlex>
                <BoxModalFlex>
                  <StyleFlexColum>
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Agents"
                      sx={{ width: "220px" }}
                      {...register("AgentID", {
                        required: "* AgentID Is Required",
                      })}
                    >
                      {AgentsData?.map((item) => (
                        <MenuItem key={item.AgentID} value={item.AgentID || ""}>
                          {item.EnglishName}
                        </MenuItem>
                      ))}
                    </TextField>
                    <ErrorText>{errors.AgentID?.message}</ErrorText>
                  </StyleFlexColum>
                  <StyleFlexColum>
                    <TextField
                      id="outlined-basic"
                      label="Collected Value"
                      variant="outlined"
                      type="number"
                      {...register("InitialPayment", {
                        required: "* InitialPayment Is Required",
                      })}
                    />
                    <ErrorText>{errors.InitialPayment?.message}</ErrorText>
                  </StyleFlexColum>
                  <StyleFlexColum>
                    <TextField
                      id="outlined-basic"
                      label="Balance"
                      type="number"
                      variant="outlined"
                      {...register("TotalValue", {
                        required: "* TotalValue Is Required",
                      })}
                    />
                    <ErrorText>{errors.TotalValue?.message}</ErrorText>
                  </StyleFlexColum>
                </BoxModalFlex>
                <BoxModalFlex>
                  <StyleFlexColum>
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Branch Name"
                      sx={{ width: "220px" }}
                      {...register("BranchID", {
                        required: "* Branch Is Required",
                      })}
                    >
                      {BranchesData?.map((item) => (
                        <MenuItem
                          key={item.BranchID}
                          value={item.BranchID || ""}
                        >
                          {item.EnglishName}
                        </MenuItem>
                      ))}
                    </TextField>
                    <ErrorText>{errors.BranchID?.message}</ErrorText>
                  </StyleFlexColum>
                  <StyleFlexColum>
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Payment Method"
                      sx={{ width: "220px" }}
                      {...register("PaymentMethodID", {
                        required: "* PaymentMethod Is Required",
                      })}
                    >
                      {PaymentData?.map((item) => (
                        <MenuItem
                          key={item.PaymentMethodID}
                          value={item.PaymentMethodID || ""}
                        >
                          {item.EnglishName}
                        </MenuItem>
                      ))}
                    </TextField>
                    <ErrorText>{errors.PaymentMethodID?.message}</ErrorText>
                  </StyleFlexColum>
                  <TextField
                    id="outlined-basic"
                    label="Notes"
                    variant="outlined"
                    {...register("Notes", {
                      required: "* Notes Is Required",
                    })}
                  />
                </BoxModalFlex>
              </BoxModalStyle>
              <BoxModal>
                <ButtonStyle type="submit">
                  {isLoading ? <CircularProgress /> : `Save`}
                </ButtonStyle>
                <ButtonStyle
                  onClick={() => {
                    setOpenUpdate(false);
                  }}
                  style={{ backgroundColor: "red", borderColor: "red" }}
                >
                  Cancel
                </ButtonStyle>
              </BoxModal>
            </BoxModalStyle>
          </form>
        </Box>
      </Modal>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={snack}
        autoHideDuration={4000}
        onClose={handleCloseSnack}
      >
        <Alert severity="error">Un Expected error Happend !</Alert>
      </Snackbar>
    </div>
  );
}
