import * as React from "react";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  MenuItem,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useQueryClient, useQuery } from "react-query";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #eee",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const TypographyLabel = styled.h2`
  font-weight: bold;
  color: #31d703;
`;

const BoxModalStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const BoxModal = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
`;
const BoxModalFlex = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
`;
const BoxModalFlexN = styled.div`
  display: flex;
  flex-direction: column;
  margin: -12px;
`;
const BoxModalFlexNew = styled.div`
  display: flex;
  justify-content: space-between;
  /* margin: 40px; */
  align-items: center;
`;

const ErrorText = styled.p`
  color: red;
`;
const ButtonStyle = styled.button`
  width: 150px;
  height: 50px;
  background-color: #31d703;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border-color: #31d703;
  border-radius: 5px;
`;
export default function AddUserModal() {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("userStorage")
    ? JSON.parse(localStorage.getItem("userStorage")).AccessToken
    : null;
  const schema = yup.object().shape({
    UserID: yup
      .string("validation.invalid_UserID")
      .required("UserID is required"),
    DisplayName: yup
      .string("validation.invalid display name")
      .required("Display Name is required"),
    Username: yup
      .string("validation.invalid user name")
      .required("Username is required"),
    UserPassword: yup
      .string("validation.invalid password")
      .required("Password is required"),
    ConfirmPassword: yup
      .string("validation.invalid password")
      .required("Confirm Password is required")
      .oneOf([yup.ref("UserPassword"), null], "Passwords must match"),
    RoleID: yup
      .string("validation.invalid password")
      .required("Role is required"),
    BranchID: yup.string("validation.Branch ID").required("Branch is required"),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
  });
  const [open, setOpen] = useState(false);
  const handleCloseSnack = () => {
    setSnack(false);
  };
  const [snack, setSnack] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const queryClient = useQueryClient();
  const { data: RoleData } = useQuery(
    "role_list",
    async () => {
      const data = await axios.get(
        "https://trackshipments.message-express.com/APIs/api/Roles/V1/GetAll",
        {
          headers: {
            CompanyID: "211801",
            AccessToken: `${accessToken}`,
          },
        }
      );
      return data.data.Data;
    },
    {
      onError: (error) => {
        if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized response
          localStorage.clear();
          navigate("/");
        }
      },
    }
  );
  const { data: BranchData } = useQuery(
    "branch_list",
    async () => {
      const data = await axios.get(
        "https://trackshipments.message-express.com/APIs/api/Branches/V1/GetAll",
        {
          headers: {
            CompanyID: "211801",
            AccessToken: `${accessToken}`,
          },
        }
      );
      return data.data.Data;
    },
    {
      onError: (error) => {
        if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized response
          localStorage.clear();
          navigate("/");
        }
      },
    }
  );
  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  async function regeisterHandler(data) {
    setDisable(true);
    setIsLoading(true);
    const userSchema = {
      UserID: 0,
      DisplayName: "",
      Username: "",
      IsEnabled: true,
      RoleID: 0,
      UserPassword: "",
    };

    try {
      const response = await axios.post(
        "https://trackshipments.message-express.com/APIs/api/Users/V1/Insert",
        { ...userSchema, ...data },
        {
          headers: {
            CompanyID: "211801",
            AccessToken: `${accessToken}`,
          },
        }
      );
      if (response.status === 401) {
        // Handle 401 Unauthorized response
        localStorage.clear();
        navigate("/");
      } else {
        // Handle other responses
        queryClient.invalidateQueries("DataGridSignUp");
        setIsLoading(false);
        setDisable(false);
        handleClose();
        reset();
      }
    } catch (error) {
      console.error(error);
      // Check if the error is due to a 401 response
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        navigate("/");
      }
      setIsLoading(false);
      setDisable(false);
    }
  }
  return (
    <div>
      <BoxModal>
        <TypographyLabel>All Users</TypographyLabel>
        <ButtonStyle onClick={handleOpen}>Create User</ButtonStyle>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form onSubmit={handleSubmit(regeisterHandler)}>
            <Box sx={style}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <TypographyLabel>Create User</TypographyLabel>
              </Box>
              <BoxModalStyle>
                <BoxModalStyle>
                  <BoxModalFlex>
                    <BoxModalFlexN>
                      <TextField
                        id="outlined-basic"
                        label="UserID"
                        defaultValue={0}
                        variant="outlined"
                        sx={{ width: "222px", display: "none" }}
                        {...register("UserID", {
                          required: "* userID Is Required",
                        })}
                        hidden
                      />
                      <ErrorText>{errors.UserID?.message}</ErrorText>
                    </BoxModalFlexN>
                    <BoxModalFlexN>
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Select Branch"
                        sx={{ width: "210px", marginLeft: "105px" }}
                        {...register("BranchID", {
                          required: "* Branch Name Is Required",
                        })}
                      >
                        {BranchData?.map((item) => (
                          <MenuItem
                            key={item.BranchID}
                            value={item.BranchID || ""}
                          >
                            {item.EnglishName}
                          </MenuItem>
                        ))}
                      </TextField>
                      <ErrorText>{errors.BranchID?.message}</ErrorText>
                    </BoxModalFlexN>
                  </BoxModalFlex>
                  <BoxModalFlex>
                    <BoxModalFlexN>
                      <TextField
                        id="outlined-basic"
                        label="Display Name"
                        variant="outlined"
                        {...register("DisplayName", {
                          required: "* display Name Is Required",
                        })}
                      />
                      <ErrorText>{errors.DisplayName?.message}</ErrorText>
                    </BoxModalFlexN>
                    <BoxModalFlexN>
                      <TextField
                        id="outlined-basic"
                        label="User Name"
                        variant="outlined"
                        {...register("Username", {
                          required: "* username Is Required",
                        })}
                      />
                      <ErrorText>{errors.Username?.message}</ErrorText>
                    </BoxModalFlexN>
                  </BoxModalFlex>
                  <BoxModalFlex>
                    <BoxModalFlexN>
                      <TextField
                        id="outlined-password-input"
                        label="Password"
                        type="password"
                        {...register("UserPassword")}
                      />
                      <ErrorText>{errors.UserPassword?.message}</ErrorText>
                    </BoxModalFlexN>
                    <BoxModalFlexN>
                      <TextField
                        id="outlined-password-input"
                        label="Confirm Password"
                        type="password"
                        {...register("ConfirmPassword")}
                      />
                      <ErrorText>{errors.ConfirmPassword?.message}</ErrorText>
                    </BoxModalFlexN>
                  </BoxModalFlex>
                  <BoxModalFlex>
                    <BoxModalFlexNew>
                      <Typography>Is Active</Typography>
                      <Checkbox name={"IsEnabled"} />
                    </BoxModalFlexNew>
                    <BoxModalFlexN>
                      <BoxModalFlex>
                        <TextField
                          id="outlined-select-currency"
                          select
                          label="Select Role"
                          sx={{ width: "210px", marginLeft: "105px" }}
                          {...register("RoleID", {
                            required: "* Role Is Required",
                          })}
                        >
                          {RoleData?.map((item) => (
                            <MenuItem
                              key={item.RoleID}
                              value={item.RoleID || ""}
                            >
                              {item.EnglishName}
                            </MenuItem>
                          ))}
                        </TextField>
                      </BoxModalFlex>
                      <ErrorText>{errors.RoleID?.message}</ErrorText>
                    </BoxModalFlexN>
                  </BoxModalFlex>
                </BoxModalStyle>
                <BoxModal>
                  <ButtonStyle type="submit">
                    {isLoading ? <CircularProgress /> : `Save`}
                  </ButtonStyle>
                  <ButtonStyle
                    onClick={handleClose}
                    style={{ backgroundColor: "red", borderColor: "red" }}
                  >
                    Cancel
                  </ButtonStyle>
                </BoxModal>
              </BoxModalStyle>
              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                open={snack}
                autoHideDuration={4000}
                onClose={handleCloseSnack}
              >
                <Alert severity="error">Un Expected error Happend !</Alert>
              </Snackbar>
            </Box>
          </form>
        </Modal>
      </BoxModal>
    </div>
  );
}
