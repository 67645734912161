import * as React from "react";
import Card from "@mui/material/Card";
import { useState, useEffect } from "react";
import { Snackbar, Alert } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import LogIn from "../../assets/images/Login.jpg";
import styled from "styled-components";
import { Box, TextField } from "@mui/material";
import * as yup from "yup";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

const ErrorText = styled.p`
  color: red;
`;
const ButtonStyle = styled.button`
  width: 220px;
  height: 50px;
  background-color: #31d703;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border-color: #31d703;
  border-radius: 5px;
`;
export default function SignIn({ isLoggedIn, setIsLoggedIn }) {
  useEffect(() => {
    document.title = "Log In";
    return () => {
      document.title = "Original Page Title";
    };
  }, []);
  const schema = yup.object().shape({
    Username: yup
      .string("validation.invalid user name")
      .required("Username is required"),
    Password: yup
      .string("validation.invalid password")
      .required("Password is required"),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
  });
  const handleCloseSnack = () => {
    setSnack(false);
  };
  const [snack, setSnack] = useState(false);
  async function registerHandler(data) {
    const LoginSchema = {
      Username: "",
      Password: "",
    };

    await axios
      .post(
        "https://trackshipments.message-express.com/APIs/api/Users/V1/Login",
        { ...LoginSchema, ...data },
        {
          headers: {
            CompanyID: "211801",
          },
        }
      )
      .then((res) => {
        const userStorage = res.data.Data;
        const userJSON = JSON.stringify(userStorage);
        localStorage.setItem("userStorage", userJSON);
        setIsLoggedIn(true);
        reset();
      })
      .catch((err) => {});
  }
  useEffect(() => {
    document.title = "Log In";
    const storedUser = localStorage.getItem("userStorage");

    if (storedUser) {
      // Parse the stored user data
      const userStorage = JSON.parse(storedUser);

      // Check if the access token exists in the parsed user data
      if (userStorage && userStorage.AccessToken) {
        setIsLoggedIn(true);
      }
    }
  }, []);
  return (
    <Card
      sx={{
        maxWidth: 345,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 500,
        bgcolor: "background.paper",
        border: "2px solid #eee",
        boxShadow: 50,
        p: 4,
        borderRadius: "10px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <img src={LogIn} alt="LogIn" width={250} />
      </Box>
      <form onSubmit={handleSubmit(registerHandler)}>
        <CardContent
          sx={{ display: "flex", flexDirection: "column", gap: "15px" }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              margin: "-15px",
            }}
          >
            <TextField
              id="outlined-basic"
              label="User Name"
              variant="outlined"
              sx={{ width: "222px" }}
              {...register("Username", {
                required: "* User Name Is Required",
              })}
            />
            <ErrorText>{errors.Username?.message}</ErrorText>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              margin: "-15px",
            }}
          >
            <TextField
              id="outlined-password-input"
              sx={{ width: "222px" }}
              label="Password"
              type="password"
              {...register("Password", {
                required: "* Password Is Required",
              })}
            />
            <ErrorText>{errors.Password?.message}</ErrorText>
          </Box>
        </CardContent>
        <CardActions sx={{ display: "flex", justifyContent: "center" }}>
          <ButtonStyle type="submit">Log In</ButtonStyle>
        </CardActions>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={snack}
          autoHideDuration={4000}
          onClose={handleCloseSnack}
        >
          <Alert severity="error">Un Expected error Happend !</Alert>
        </Snackbar>
      </form>
    </Card>
  );
}
