import React from "react";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import axios from "axios";
import { useQueryClient } from "react-query";
import { Alert, Box, Snackbar, CircularProgress, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #eee",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};
const TypographyText = styled.h2`
  display: grid;
  place-items: center;
`;

const FlexDivCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  margin-bottom: 25px;
`;
const ButtonStyle = styled.button`
  width: 150px;
  height: 50px;
  background-color: #31d703;
  color: #eee;
  font-size: 16px;
  font-weight: bold;
  border-color: #31d703;
  border-radius: 5px;
`;
export default function DeleteShipment(props) {
  const navigate = useNavigate();
  const { openDelete, setOpenDelete, currentRow, loading, setLoading } = props;
  const [snack, setSnack] = useState(false);
  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const handleClose = (shipment) => {
    if (shipment === "clickaway") {
      return;
    }
    setSnack(false);
  };
  const handleDelete = (e) => {
    const accessToken = localStorage.getItem("userStorage")
      ? JSON.parse(localStorage.getItem("userStorage")).AccessToken
      : null;
    e.preventDefault();
    setIsLoading(true);

    axios
      .delete(
        `https://trackshipments.message-express.com/APIs/api/Shipments/V1/Delete/${currentRow.ShipmentID}`,
        {
          headers: {
            CompanyID: "211801",
            AccessToken: `${accessToken}`,
          },
        }
      )
      .then((res) => {
        queryClient.invalidateQueries("ShipmentDataGrid");
        setOpenDelete(false);
        setIsLoading(false);
        setDisable(false);
      })
      .catch((err) => {
        console.log(err);

        if (err.response && err.response.status === 401) {
          // Handle 401 Unauthorized response
          localStorage.clear();
          navigate("/"); // Assuming you have imported the navigate function from your router library
        } else {
          setSnack(true);
        }

        setIsLoading(false);
        setDisable(false);
      });
  };
  return (
    <div>
      <Modal open={openDelete}>
        <form onSubmit={handleDelete}>
          <Box sx={style}>
            <FlexDivCenter>
              <WarningAmberOutlinedIcon
                sx={{
                  fontSize: 100,
                  color: "#ffb818",
                }}
              />
              <TypographyText>
                Are You Sure You Want To Delete This Shipment ?
              </TypographyText>
            </FlexDivCenter>
            <FlexDivCenter>
              <ButtonStyle variant="contained" color="primary" type="submit">
                {loading ? <CircularProgress color="secondary" /> : "Delete"}
              </ButtonStyle>
              <ButtonStyle
                onClick={() => {
                  setOpenDelete(false);
                }}
                variant="contained"
                color="primary"
                style={{ backgroundColor: "red", borderColor: "red" }}
              >
                Cancel
              </ButtonStyle>
            </FlexDivCenter>
          </Box>
        </form>
      </Modal>
      <Box>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={snack}
            autoHideDuration={4500}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              {"Un Expected error"}
            </Alert>
          </Snackbar>
        </Stack>
      </Box>
    </div>
  );
}
